#cookieBanner {
  display: none;
  position: fixed;
  left: 50%;
  bottom: 3rem;
  width: 90%;
  max-width: 55rem;
  transform: translateX(-50%);
  background: $color-white;
  box-shadow: 0 0 1rem $color-black;
  border-radius: $border-radius;
  padding: 1.5rem;
  .row {
    min-height: 10rem;
    .iconWrapper {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      .innerWrapper {
        img {
          display: block;
          margin: 0 auto 1rem auto;
        }
        span {
          display: block;
          text-align: center;
          font-size: $font-size-h3;
          font-weight: $font-weight-bold;
          margin-bottom: 1rem;
          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }
      }
    }
    .infoTextWrapper {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      .innerWrapper {
        @include font-copy-m;
        @include media-breakpoint-up(lg) {
          @include font-copy-l;
        }
        a {
          font-weight: $font-weight-semi-bold;
        }
      }
    }
    .buttonWrapper {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      margin-top: 0.75rem;
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }
}

html.no-js,
body.has-edjufyCookiesAccepted {
  #cookieBanner {
    display: none !important;
    visibility: hidden !important;
  }
}