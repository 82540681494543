.area-cta {
  padding: 0;
}
.area-cta,
.area-leadform {
  .ctaBrickWrapper {
    position: relative;
    padding: $brick-padding 0;
    background: $color-green-light;

    &.has-backgroundImage {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &.has-parallaxBackground {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
    }
    &.has-boxBorder {
      border-top: 0.25rem solid transparent;
      border-bottom: 0.25rem solid transparent;
    }

    .boxBorder {
      position: absolute;
      left: 0;
      right: 0;
      height: 0.25rem;
      &.topBorder {
        top: -3px;
      }
      &.bottomBorder {
        bottom: -0.25rem;
      }
      &.boxBorder-green {
        background: $color-green-gradient;
      }
      &.boxBorder-orange {
        background: $color-orange-gradient;
      }
    }

    .backgroundOverlay {
      @include background-overlay;
    }

    .content {
      .title {
        @include font-h4;
        margin-bottom: 0.5rem;
      }

      .text {
      }

      &.textColor-black {
        .title,
        .text {
          color: $color-black;
        }
      }
      &.textColor-white {
        .title,
        .text {
          color: $color-white;
        }
      }

      .buttonWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      form.emailLeadForm {
        position: relative;
        width: 100%;
        margin-top: 0.75rem;
        @include media-breakpoint-up(md) {
        }
        .form-group {
          margin-bottom: 0;
        }
        .privacyNote {
          @include font-copy-s;
          position: relative;
          display: block;
          opacity: 0;
          padding: 0 0.25rem;
          margin-top: 0.25rem;
          transition: opacity .5s ease;
          &.is-visible {
            opacity: 1;
          }
        }
      }
    }
  }
}