#announcementOverlayModal {
  .modal-body {
    padding: 1rem 1.5rem;
    .btn.close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.75rem;
    }
    .announcementOverlayTitle {
      font-size: $font-size-h3;
      font-weight: $font-weight-bold;
      margin-top: 0.5rem;
      margin-bottom: 1.25rem;
    }
  }
}
