#siteWrapper {
  max-width: $site-wrapper-width;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
  padding-top: $header-height-mobile;
  box-shadow: 0 0 5px #ECECEC;
  @include media-breakpoint-up(sm) {
    padding-top: $header-height-tablet;
  }
  @include media-breakpoint-up(lg) {
    padding-top: $header-height-desktop;
  }
  body.editmode & {
    padding-top: 0;
  }
  .defaultInnerContentWrapper {
    > section:first-of-type {
      padding-top: 1rem;
    }
  }
}

body #outdated {
  top: auto;
  bottom: 0;
  height: 130px;
  h6 {
    margin: 20px 0 10px;
  }
  #btnUpdateBrowser {
    margin: 15px auto 0;
  }
}

.medium-zoom-overlay,
.medium-zoom-image--opened {
  z-index: 999;
}