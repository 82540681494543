section.header-stage {
  position: relative;
  background: $color-green-light;
  height: 0;
  overflow: hidden;
  padding-top: 70%;
  padding-bottom: 0.25rem;
  .bottomBorder {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.25rem;
    background: $color-green-gradient;
  }

  @include media-breakpoint-up(sm) {
    padding-top: 47%;
  }
  @include media-breakpoint-up(md) {
    padding-top: 55%;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 31%;
  }

  body.editmode & {
    height: auto;
    padding-top: 0;
    background: none;
    border-bottom: 0;
  }

  .stage-snippet {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0.25rem;
    overflow: hidden;
    .slider {
      padding: 0;
      margin: 0;
      height: 100%;
      .slick-list {
        height: 100%;
        .slick-track {
          height: 100%;
        }
      }
    }
    .slide {
      position: relative;
      height: 100%;
      outline: none;
      .background,
      .background img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .backgroundOverlay {
        @include background-overlay;
      }
      .mobileImageOverlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        &.textColor-white {
          background: rgba(0,0,0,0.6);
        }
        &.textColor-black {
          background: rgba(255,255,255,0.6);
        }
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
      .container {
        height: 100%;
      }
      .content {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        max-width: 33rem;
        @include media-breakpoint-up(md) {
          width: 45%;
        }
        .headline {
          @include font-h2();
          color: $color-black;
          margin-bottom: 0.25rem;
        }
        .subline {
          @include font-h4();
          color: $color-black;
          margin-bottom: 0.25rem;
        }
        .text {
          @include font-copy-m();
          @include media-breakpoint-up(sm) {
            @include font-copy-l();
            margin-top: 0.75rem;
          }
        }
        &.textColor-white {
          .headline,
          .subline,
          .text {
            color: $color-white;
          }
        }
        &.textColor-black {
          .headline,
          .subline,
          .text {
            color: $color-black;
          }
        }
        .buttons {
          margin-top: 1rem;
          @include media-breakpoint-up(md) {
            margin-top: 2rem;
          }
          ul {
            li {
              margin: 0 0.25rem 0.5rem 0;
            }
          }
        }
      }
    }
    .slide-down-button {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -0.5rem;
      cursor: pointer;
      animation: slideDownButtonJump 1.5s 4 ease-in-out 6s both;

      &.has-controls {
        bottom: 1.5rem;
      }
      i {
        font-size: 2.75rem;
        color: $color-primary;
        @include media-breakpoint-up(md) {
          font-size: 3.75rem;
        }
      }
    }
    .slider-controls {
      > div {
        position: absolute;
        top: 50%;
        color: $color-black;
        font-size: 2rem;
        transform: translateY(-50%);
        cursor: pointer;
      }
      .slider-prev {
        left: 2rem;
      }
      .slider-next {
        right: 2rem;
      }
    }
    .slick-dots {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      padding: 0;
      li {
        display: inline-block;
        button {
          width: 12px;
          height: 12px;
          background: $stage-navigation-color;
          border-radius: 12px;
          font-size: 1px;
          text-indent: -9999px;
          margin: 0 3px;
          border: none;
          padding: 0;
          cursor: pointer;
          outline: none;
        }
        &.slick-active button {
          background: $stage-navigation-active-color;
        }
      }
    }
  }
}

@keyframes slideDownButtonJump {
  0% {
    bottom: -0.5rem;
  }
  50% {
    bottom: 0.5rem;
  }
  100% {
    bottom: -0.5rem;
  }
}