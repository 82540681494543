/*-------------------------------------------------------------------------*/
/*	Global */
/*-------------------------------------------------------------------------*/

body {
    @include font-copy-l();
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// making the xl container a bit wider
@media (min-width: 1250px) {
    .container {
        max-width: 1200px;
    }
}

h1, .h1 {
    @include font-h1();
}

h2, .h2 {
    @include font-h2();
}

h3, .h3 {
    @include font-h3();
}

h4, .h4 {
    @include font-h4();
}

h5, .h5 {
    @include font-h5();
}

a {
    color: $color-green;
    text-decoration: none;
    &:hover {
        color: $color-green-active;
        text-decoration: none;
    }
}

.hidden {
    display: none !important;
}

// form error stylings
.has-error,
.has-warning {
    .form-control-feedback {
        color: $danger;
    }
    .form-control {
        border-color: $danger;
    }
}

// buttons
.btn {
    position: relative;
    font-size: 0.875rem;
    font-weight: $font-weight-semi-bold;
    line-height: 1.1rem;
    letter-spacing: 1px;
    padding: 0.55rem 0.75rem;

    @include media-breakpoint-up(sm) {
        font-size: 1rem;
        font-weight: $font-weight-bold;
        line-height: 1.2rem;
        letter-spacing: 1px;
        padding: 0.75rem 1.75rem;
    }

    &.btn-default {
        background: $color-light-grey;
        color: $color-black;
        &:hover {
            background: $color-light-grey-active;
        }
    }

    &.btn-outline-primary {
        border: 2px solid $color-primary;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            transform: scale3d(0.1, 1, 1);
            transition: transform 0.6s, opacity 0.6s;
            transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
            background: $color-green-active;
        }
        &:hover {
            color: $color-white;
            &::before {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }
    }
    &.btn-outline-secondary {
        border: 2px solid $color-orange;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            transform: scale3d(0.1, 1, 1);
            transition: transform 0.6s, opacity 0.6s;
            transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
            background: $color-orange-active;
        }
        &:hover {
            color: $color-white;
            &::before {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }
    }
}

// list styles
@mixin checkMarkList($checkMarkColor) {
    padding: 0;
    li {
        position: relative;
        list-style: none;
        padding-left: 1.25rem;
        margin-bottom: 0.25rem;
        @include media-breakpoint-up(sm) {
            padding-left: 1.75rem;
            margin-bottom: 0.5rem;
        }
        @include media-breakpoint-up(md) {
            padding-left: 2.25rem;
            margin-bottom: 0.75rem;
        }
        &:before {
            @include font-h4();
            @include fa-icon();
            font-weight: bold;
            content: '\f00c';
            color: $checkMarkColor;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
}

ul.blackCheckmarkList {
    @include checkMarkList($color-black);
}

ul.greenCheckmarkList {
    @include checkMarkList($color-green);
}

ul.orangeCheckmarkList {
    @include checkMarkList($color-orange);
}

section.area-section ul {
    padding-left: 1.25rem;
    li {
        margin-bottom: 0.25rem;
    }
}

/******************
 * COOKIE POLICY
 ******************/
#pc-cookie-notice {

    #pc-button {
        button {

        }
    }
}

/* Change the white to any color ;) */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}