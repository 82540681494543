section.area-faq-list.brick {
  .faqItemList {
    .faqItem {
      border: 1px solid $color-light-grey;
      border-radius: $border-radius;
      transition: all 0.3s;
      margin-bottom: 1.5rem;

      .questions {
        @include font-copy-l();
        position: relative;
        font-weight: $font-weight-bold;
        letter-spacing: 0.65px;
        padding: 2.25rem 4rem 2.25rem 2.5rem;
        cursor: pointer;
        i {
          position: absolute;
          top: 50%;
          right: 2rem;
          font-size: 1.25rem;
          transform: translateY(-50%);
          transition: transform 0.3s;
        }
      }
      .answer {
        overflow: hidden;
        height: 0;
        padding: 0 4rem 0 2.5rem;
        transition: all 0.3s;
        @include media-breakpoint-up(lg) {
          padding: 0 13.5rem 0 2.5rem;
        }
      }
      &.is-open {
        box-shadow: 0 0 3.5rem 0 rgba(0,0,0,0.4);
        .questions {
          i {
            transform: translateY(-50%) rotate(45deg);
            color: $color-primary;
          }
        }
        .answer {
          height: auto;
          padding-bottom: 2rem;
        }
      }
    }
  }
}