nav.breadcrumbs {
  margin: 0.5rem 0 1rem 0;
  ol {
    list-style: none;
    padding-left: 0;
    li {
      display: inline-block;
      margin-right: 0.25rem;
      .fa-angle-right {
        position: relative;
        top: 1px;
        color: $color-green;
      }
      a {
        color: $color-black;
        font-weight: $font-weight-medium;
        &:hover {
          color: $color-green;
        }
      }
      &:last-of-type {
        a {
          font-weight: $font-weight-normal;
          color: $color-black;
        }
      }
    }
  }
}