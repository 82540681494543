.area-feature-boxes {
  background: $color-green-light;

  body.editmode & {
    .featureBoxItems {
      .pimcore_tag_block {
        display: flex;
        flex-wrap: wrap;
      }

      .pimcore_block_entry {
        width: 50%;
        clear: none;
        padding: 0 15px;
      }
    }
  }
  &__content {
    padding: 4rem 0;
    .featureBoxItems {
      .featureBoxItemWrapper {
        height: 100%;
        padding-bottom: 1.875rem;
        .featureBoxItem {
          display: block;
          height: 100%;
          background: $color-white;
          box-shadow: $default-shadow;
          border-radius: $border-radius;
          padding: 2rem;
          transition: box-shadow 0.5s;
          &.has-link:hover {
            box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.4);
          }

          .headline {
            @include font-copy-m();
            font-weight: $font-weight-bold;
            margin-bottom: 1.25rem;
            letter-spacing: 0.64px;
          }

          .content {
            @include font-copy-m();

            p:last-of-type {
              margin-bottom: 0;
            }
          }

          .buttonWrapper {
            margin-top: 0.75rem;
            a.readMoreLink {
              &:after {
                position: relative;
                top: 1px;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                font-family: $font-family-font-awesome;
                content: "\f138";
                margin-left: 0.25rem;
              }
            }
          }
        }
      }
    }
  }
}