/* === Bootstrap Global === */
$enable-rounded: true;

$site-wrapper-width: 120rem;

/* === Header / Navigation === */
$header-height-mobile: 3.125rem; // 50px
$header-height-tablet: 6.0625rem; // 65px
$header-height-desktop: 6.25rem;

$header-height-main-content-tablet: 4.0625rem;
$header-height-main-content-desktop: 3.5rem;

$header-space-mobile: 0.25rem;
$header-space-tablet: 0.25rem;
$header-space-desktop: 0.25rem;

$top-nav-height: 2.5rem;
$top-nav-space: 1.25rem;


/* === Colors === */
$color-black: #1F1F1F;
$color-white: #FFFFFF;
$color-green: #338B24;
$color-green-active: #0F6500;
$color-green-light: #f4f9f3;
$color-orange: #EE571D;
$color-orange-active: #BB2500;
$color-orange-light: #fef6f3;
$color-red: #d7352f;
$color-red-active: #A40200;
$color-blue: #0252cc;
$color-blue-active: #0047B3;
$color-light-grey: #F2F2F2;
$color-light-grey-active: #BFBFBF;
$color-grey: #B0B0B0;
$color-grey-active: #BFBFBF;
$color-grey-border: #ced4da;

$color-primary: $color-green;

$color-green-gradient: transparent linear-gradient(90deg, $color-green 0%, $color-green-active 100%) 0 0 no-repeat padding-box;
$color-orange-gradient: transparent linear-gradient(90deg, $color-orange 0%, $color-orange-active 100%) 0 0 no-repeat padding-box;

$theme-colors: (
        "primary":    $color-primary,
        "secondary":  $color-orange,
        "success":    $color-green,
        "info":       $color-green-light,
        "warning":    $color-orange,
        "danger":     $color-red,
        "light":      $color-light-grey,
        "dark":       $color-black
);

/* === Styles === */
$border-radius: 0.25rem;
$default-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);

/* === Bricks === */
$brick-padding: 3rem;
$brick-in-brick-padding: 1.25rem;

$brick-header-bottom-space: 30px;
$brick-footer-top-space: 30px;

/* === Stage === */
$fix-slider-height: 37.5rem;
$stage-text-color: $color-white;
$stage-navigation-color: $color-white;
$stage-navigation-active-color: $color-primary;

/* === Font === */

$font-weight-extra-light: 200;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-heavy: 800;

$font-family-galano-grotesque: 'GalanoGrotesque', sans-serif;
$font-family-font-awesome: 'Font Awesome 5 Pro';

$font-size-h1: 3.125rem; // 50px
$font-size-h1-flow: $font-size-h1 * 0.6, $font-size-h1 * 0.8, $font-size-h1 * 0.9, $font-size-h1;
$line-height-h1: 3.75rem; // 60px
$line-height-h1-flow: $line-height-h1 * 0.6, $line-height-h1 * 0.8, $line-height-h1 * 0.9, $line-height-h1;

$font-size-h2: 2.1875rem; // 35px
$font-size-h2-flow: $font-size-h2 * 0.65, $font-size-h2 * 0.8, $font-size-h2 * 0.9, $font-size-h2;
$line-height-h2: 2.625rem; // 42px
$line-height-h2-flow: $line-height-h2 * 0.65, $line-height-h2 * 0.8, $line-height-h2 * 0.9, $line-height-h2;

$font-size-h3: 1.875rem; // 30px
$font-size-h3-flow: $font-size-h3 * 0.65, $font-size-h3 * 0.8, $font-size-h3 * 0.9, $font-size-h3;
$line-height-h3: 2.25rem; // 36px
$line-height-h3-flow: $line-height-h3 * 0.65, $line-height-h3 * 0.8, $line-height-h3 * 0.9, $line-height-h3;

$font-size-h4: 1.5625rem; // 25px
$font-size-h4-flow: $font-size-h4 * 0.65, $font-size-h4 * 0.8, $font-size-h4 * 0.9, $font-size-h4;
$line-height-h4: 1.875rem; // 30px
$line-height-h4-flow: $line-height-h4 * 0.65, $line-height-h4 * 0.8, $line-height-h4 * 0.9, $line-height-h4;

$font-size-h5: 1rem; // 16px
$font-size-h5-flow: $font-size-h5 * 0.8, $font-size-h5 * 0.8, $font-size-h5 * 0.9, $font-size-h5;
$line-height-h5: 1.2rem; // 19,2px
$line-height-h5-flow: $line-height-h5 * 0.8, $line-height-h5 * 0.8, $line-height-h5 * 0.9, $line-height-h5;

$font-size-copy-xl: 1.125rem; // 18px
$font-size-copy-xl-flow: $font-size-copy-xl, $font-size-copy-xl, $font-size-copy-xl, $font-size-copy-xl;
$line-height-copy-xl: 1.5625rem; // 25px
$line-height-copy-xl-flow: $line-height-copy-xl, $line-height-copy-xl, $line-height-copy-xl, $line-height-copy-xl;

$font-size-copy-l: 1rem; // 16px
$font-size-copy-l-flow: $font-size-copy-l, $font-size-copy-l, $font-size-copy-l, $font-size-copy-l;
$line-height-copy-l: 1.4375rem; // 23px
$line-height-copy-l-flow: $line-height-copy-l, $line-height-copy-l, $line-height-copy-l, $line-height-copy-l;

$font-size-copy-m: 0.875rem; // 14px
$font-size-copy-m-flow: $font-size-copy-m, $font-size-copy-m, $font-size-copy-m, $font-size-copy-m;
$line-height-copy-m: 1.3125rem; // 21px
$line-height-copy-m-flow: $line-height-copy-m, $line-height-copy-m, $line-height-copy-m, $line-height-copy-m;

$font-size-copy-s: 0.75rem; // 12px
$font-size-copy-s-flow: $font-size-copy-s, $font-size-copy-s, $font-size-copy-s, $font-size-copy-s;
$line-height-copy-s: 1.0625rem; // 17px
$line-height-copy-s-flow: $line-height-copy-s, $line-height-copy-s, $line-height-copy-s, $line-height-copy-s;