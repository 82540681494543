div.modal-backdrop.show {
  opacity: 0.8;
}

div.leadModal {
  .modal-dialog {
    max-width: 1445px;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .modal-header {
    padding-top: 1.5rem;
    border-bottom: none;

    .titleWrapper {
      display: block;
      width: 100%;
      padding-left: 1.75rem;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0.5rem;
      }

      .title {
        @include font-h2;
        display: block;
        text-align: center;
      }
      .infoText {
        text-align: center;
        margin-top: 0.25rem;
      }
    }
  }

  .modal-body {
    padding-top: 0;

    .leadModalError {
      display: none;
    }

    .contactImageWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      .contactImage {
        max-width: 70%;
      }
    }

    .successImageWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      .successImage {
        max-width: 18rem;
      }
    }

    .formHeadline {
      margin-bottom: 1rem;
    }

    .formFieldsWrapper {
      position: relative;
      .formLoadingOverlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.8);
      }
    }
    .form-group {
      label {
        font-weight: $font-weight-semi-bold;
        margin-bottom: 0.25rem;
        .optionalLabel {
          font-weight: $font-weight-regular;
        }
      }
      input {
        &::placeholder {
          color: $color-grey;
        }
      }
    }
    .selectGroup {
      display: flex;
      margin-bottom: 0.5rem;
      .selectItem {
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }
        label {
          background: $color-white;
          border: 1px solid $color-grey-border;
          padding: 0.5rem 1.75rem;
          line-height: 1.3rem;
          border-right: none;
          cursor: pointer;
          font-weight: $font-weight-medium;
          &:hover {
            background: $color-green-light;
          }
          &:focus,
          &:focus-visible {
            border: 1px solid $color-green;
            outline: 0;
            &:last-of-type {
              border: 1px solid $color-green;
            }
          }
        }
        input:checked + label {
          background: $color-green;
          color: $color-white;
        }
        &:first-of-type label {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }
        &:last-of-type label {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
          border-right: 1px solid $color-grey-border;
        }
      }
    }
    .contactTypeSelectButtonWrapper {
      display: block;
      text-align: center;
      margin-bottom: 1.5rem;
      button {
        display: block;
        margin: 0 auto;
        @include media-breakpoint-up(lg) {
          display: inline;
          margin: 0 1rem;
        }
      }
      .contactTypeSelectButtonSeparator {
        display: block;
        margin: 1rem 0;
        @include media-breakpoint-up(lg) {
          display: inline-block;
          margin: 0;
        }
      }
    }
    .submitButtonWrapper {
      display: block;
      width: 100%;
      margin-top: 1rem;
    }
    .privacyNote {
      @include font-copy-s;
      position: relative;
      display: block;
      width: 100%;
      margin-top: 1.25rem;
    }
    .infoTextWrapper {
      margin: 0;
    }
  }
  .modal-footer {
    .leadModalCallInfo {
      @include font-copy-xl;
      display: block;
      width: 100%;
      text-align: center;
      margin: 1rem 0;
      strong {
        word-break: keep-all;
        white-space: nowrap;
      }
    }
  }
}