$headline-base-line-height: 1.875rem;

@mixin headline-line-height {
  line-height: $headline-base-line-height * 0.65;
  @include media-breakpoint-up(sm) {
    line-height: $headline-base-line-height * 0.8;
  }
  @include media-breakpoint-up(md) {
    line-height: $headline-base-line-height * 0.9;
  }
  @include media-breakpoint-up(lg) {
    line-height: $headline-base-line-height;
  }
}

@mixin headline-double-min-height {
  min-height: $headline-base-line-height * 2 * 0.65;
  @include media-breakpoint-up(sm) {
    min-height: $headline-base-line-height * 2 * 0.8;
  }
  @include media-breakpoint-up(md) {
    min-height: $headline-base-line-height * 2 * 0.9;
  }
  @include media-breakpoint-up(lg) {
    min-height: $headline-base-line-height * 2;
  }
}

.area-teaser {

  .teaser-items {
    .pimcore_tag_block {
      display: flex;
      flex-wrap: wrap;
    }
    &--twoColumn {
      .pimcore_block_entry {
        width: 50%;
        clear: none;
        padding: 0 15px;
      }
    }
    &--threeColumn {
      .pimcore_block_entry {
        width: 33.3333333333%;
        clear: none;
        padding: 0 15px;
      }
    }
    &--panorama {
      .pimcore_block_entry {
        width: 100%;
        clear: none;
        padding: 0 15px;
      }
    }
  }


  &__items {
    padding: 0;
    .teaser-item {
      margin-bottom: 2rem;
    }

    .teaser-object {
      position: relative;
      height: 100%;
      width: 100%;
      margin: 0 auto;
      border: 1px solid $color-light-grey;
      border-radius: $border-radius;
      overflow: hidden;
      padding-bottom: 1rem;
      transition: box-shadow 0.5s;

      &.has-link:hover {
        box-shadow: $default-shadow;
      }

      &__image-wrapper {
        position: relative;
        width: 100%;
        padding-top: 59.786%;
      }
      picture.teaser-object__image,
      picture.teaser-object__image img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        background: $color-green-light;
      }
      &__label {
        @include font-copy-s();
        font-weight: $font-weight-semi-bold;
        position: absolute;
        left: 0.75rem;
        bottom: 0.125rem;
        padding: 0.25rem 0.5rem;
        color: $color-white;
        background: $color-primary;
        border-radius: $border-radius;
        transform: translateY(50%);
      }
      &__content {
        padding: 1.5rem 1rem 0 1rem;
        &.has-image {
          border-top: 1px solid $color-light-grey;
        }
        &.has-link {
          padding-bottom: 1rem;
        }
      }
      &__sub-headline {
        @include font-copy-s();
        font-weight: $font-weight-heavy;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        @include headline-line-height();
      }
      &__headline {
        @include font-h4();
        display: block;
        margin-bottom: 0.5rem;
        hyphens: auto;
        word-break: break-word;
        @include headline-line-height();

        &.has-two-lines {
          @include headline-double-min-height();
        }
      }
      &__text {
        @include font-copy-m();
      }
      &__link {
        position: absolute;
        left: 1rem;
        bottom: 0.75rem;
        font-weight: $font-weight-bold;
        letter-spacing: 1px;
        i {
          position: relative;
          top: 1px;
          margin-left: 0.25rem;
        }
      }
    }

    &--twoColumn {
      .teaser-object {
        max-width: 23.125rem;
        @include media-breakpoint-up(md) {
          max-width: none;
        }
      }
    }
    &--threeColumn {
      .teaser-object {
        max-width: 23.125rem;
      }
    }
    &--panorama {
      .teaser-item {
        min-height: 14rem;
        .teaser-object {
          padding-bottom: 0;
          > .row {
            height: 100%;
          }
          &__label {
            bottom: 0.25rem;
            left: 50%;
            transform: translateX(-50%);
          }
          &__text {
            line-height: 1.25rem;
            overflow: hidden;
            max-height: 7.5rem;
            text-overflow: ellipsis;
            word-break: break-word;
            hyphens: auto;
            p {
              margin-bottom: 1.25rem;
            }
          }
        }
        .imageColumn {
          height: 100%;
        }
        .contentColumn {
          height: 100%;
          padding-top: 1rem;
          padding-bottom: 2.5rem;
        }
      }
    }


    &--slider {
      position: relative;
      padding: 0 0;
      padding-bottom: 30px;
      .teaser-items__row {
        margin: 0;
        outline: none;
      }
      .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        outline: none;
        width: 30px;
        height: 30px;
        svg {
          width: 30px;
          height: 30px;
        }
        &.prev {
          left: -30px;
        }
        &.next {
          right: -30px;
        }
      }
    }

    &--masonry {
      margin-left: -15px;
      margin-right: -15px;
      .teaser-item {
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 0;
        margin-bottom: 15px;
        float: left;
        &--twoColumn {
          width: 50%;
        }
        &--threeColumn {
          width: 33.333333%;
        }
      }
    }

    .slick-dots {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        button {
          width: 12px;
          height: 12px;
          background: $color-grey;
          border-radius: 12px;
          font-size: 1px;
          text-indent: -9999px;
          margin: 0 3px;
          border: none;
          padding: 0;
          cursor: pointer;
          outline: none;
        }
        &.slick-active button {
          background: $color-primary;
        }
      }
    }

  }
}