section.area-pricecalculator {
  .area-price-calculator__content {
    .colHeadline {
      margin-bottom: 1rem;
    }

    .featureBoxes {
      margin-bottom: 30px;
      @include media-breakpoint-up(lg){
        margin-bottom: 0;
      }

      > .row {
        margin: 0 -10px;
      }
      .featureBox {
        padding: 10px;
        list-style: none;
        .content {
          display: flex;
          position: relative;
          padding: 0.75rem 0.75rem 0.75rem 2.75rem;
          background: $color-light-grey;
          height: 100%;
          align-items: center;
          i {
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
            color: $color-primary;
            margin-right: 0.5rem;
          }
        }
      }
    }

    #priceSlider {
      width: 100%;
    }

    .priceSliderWrapper {
      position: relative;
      padding: 30px 0;
      .sliderLabel {
        font-weight: $font-weight-semi-bold;
        font-size: 12px;
      }
      .startLabel {
        position: absolute;
        top: 0;
        left: 0;
      }
      .endLabel {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .rangeslider {
      position: relative;
      width: 100%;
      height: 4px;
      background: $color-grey;
      border-radius: 2px;
      .rangeslider__fill {
        position: absolute;
        height: 4px;
        background: $color-primary;
        border-radius: 2px;
      }
      .rangeslider__handle {
        position: absolute;
        top: 2px;
        width: 25px;
        height: 25px;
        background: $color-white;
        border: 2px solid $color-primary;
        border-radius: 25px;
        transform: translateY(-50%);
        cursor: pointer;
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          font-family: "Font Awesome 5 Pro";
          font-weight: 900;
          content: "\f07e";
          color: $color-orange;
        }
      }
    }

    .calculationWrapper {
      position: relative;
      border-radius: 0.25rem;
      margin-bottom: 15px;

      .pupilRow {
        border: 1px solid $color-green;
        //border-radius: 0.25rem;
        background: $color-green-light;
        margin-bottom: 15px;
        .row {
          position: relative;
          .multipleSymbol {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: $font-weight-bold;
            font-size: 25px;
            color: $color-primary;
          }
        }
        #pupilsPriceValue {
          font-weight: $font-weight-semi-bold;
        }
      }
      .subTotalRow {
        border: 1px solid $color-grey;
        margin-bottom: 15px;
      }
      .totalRow {
        border: 1px solid $color-grey;
        .legalInfo {
          color: $color-black;
          font-size: 12px;
          line-height: 12px;
        }
      }

      .individualOfferOverlay {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255,255,255,0.9);
        .innerWrapper {
          position: relative;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
          padding: 15px;
          text-align: center;
          .title {
            font-weight: $font-weight-bold;
            font-size: 25px;
            margin-bottom: 10px;
          }
          .infoText {

          }
        }
      }

      .valueLabelBox {
        text-align: center;
        padding: 5px;
        &.has-topLine {
          border-top: 1px solid $color-black;
        }
        &.has-bottomLine {
          border-bottom: 1px solid $color-black;
        }
        .valueWrapper {
          line-height: 28px;
        }
        .value {
          font-size: 25px;
          line-height: 28px;
          padding: 5px 0;
        }
        .valueLabel {
          font-weight: $font-weight-semi-bold;
          font-size: 13px;
        }
        &.pupilsAmountLabel {
          .value {
            font-weight: $font-weight-bold;
            color: $color-primary;
          }
        }
        &.pupilPriceLabel {
          .value {
            strong {
              font-weight: $font-weight-bold;
              color: $color-primary;
            }
          }
        }
        &.inlinePriceLabel {
          margin-bottom: 0;
          padding: 0 5px;
          .value {
            display: inline-block;
            font-size: 18px;
          }
          .valueLabel {
            display: inline-block;
          }
        }
        &.totalPriceLabel {
          padding: 10px 5px;
          background: $color-orange-light;
          margin-bottom: 0;
          .valueWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            .valuePrefix {
              margin-right: 5px;
            }
            .value {
              font-weight: $font-weight-heavy;
              color: $color-orange;
            }
          }
        }
      }
    }
    .priceCtaButton {
      display: block;
      width: 100%;
    }
  }
}