@include media-breakpoint-down(md) {
	.mobileNavigationCloseHelper {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		height: 100vh;
		display: none;
		z-index: 219;
	}
	header nav.main-navigation {
		background-color: $color-white;
		position: fixed;
		left: 0;
		top: $header-height-mobile;
		bottom: 0;
		height: calc(100vh - 3.125rem);
		overflow-y: auto;
		box-shadow: 0 0 10px 0 rgba($color-grey, 0.3);
		padding: 0 0 $grid-gutter-width / 2 0;
		width: 300px;
		z-index: 220;
		transition: transform 0.4s ease-out;
		transform: translate(-120%, 0);

		@include media-breakpoint-up(sm) {
			top: $header-height-tablet;
		}

		.mobileContactInfo {
			background: $color-primary;
			text-align: left;
			color: $color-white;
			padding: 0.5rem;
			margin-bottom: 1rem;
			> div {
				margin-bottom: 0.25rem;
			}
			a {
				color: $color-white;
				font-weight: $font-weight-semi-bold;
			}
		}

		ul.list {
			top: 0;
			padding: 0 0.5rem;
			margin: 1rem 0 0 0;
			height: auto;
			transform: translate(0%, 0);
			text-align: left;

			li.item {
				position: relative;
				display: block;
				font-size: 1rem;
				font-weight: $font-weight-bold;
				letter-spacing: 0.5px;
				margin-bottom: 0.5rem;
				width: 100%;
				//padding-left: 0.75rem;
				//padding-right: 0.5rem;

				&.is-active {
					.sub-navigation {
						display: block;
					}
				}

				&.has-sub-navigation-tmp {
					&:before {
						@include font-h4();
						@include fa-icon();
						font-weight: bold;
						content: '\f054';
						color: $color-primary;
						position: absolute;
						left: -0.25rem;
						top: 3px;
						cursor: pointer;
					}
				}

				a {
					display: inline-block;
					padding: 0.5rem 0.5rem;
					color: $color-black;
					transition: all .3s ease-in-out;

					&.active,
					&:hover {
						color: $color-primary;
						text-decoration: none;
					}
				}

				&.nav-button-primary {
					margin: 0.5rem 0.25rem 0.75rem 0.25rem;

					a {
						background: $color-green;
						color: $color-white;
						padding: 0.75rem 1rem;
						border-radius: $border-radius;
						@include media-breakpoint-up(xl) {
							padding: 0.75rem 1.75rem;
						}

						&:hover {
							background: $color-green-active;
						}
					}
				}

				&.nav-button-default {
					margin: 0.5rem 0.25rem 0.75rem 0.25rem;

					a {
						background: $color-light-grey;
						color: $color-black;
						padding: 0.75rem 1rem;
						border-radius: $border-radius;
						@include media-breakpoint-up(xl) {
							padding: 0.75rem 1.75rem;
						}

						&:hover {
							background: $color-light-grey-active;
						}
					}
				}

				.sub-navigation {
					display: none;
					flex: 1;
					position: relative;
					background: none;
					box-shadow: none;
					width: auto;
					top: 0;
					left: 0;
					padding: 0 0 0 1rem;
					li {
						margin-bottom: 0.75rem;
						a {
							padding: 0;
							font-weight: $font-weight-regular;
						}
					}
				}
			}
		}
	}
	body.mobile-menu-open {
		.mobileNavigationCloseHelper {
			display: block;
		}
		nav.main-navigation {
			transform: translate(0%, 0);
		}
	}
}