section.area-wysiwyg-media.brick {

  .content {
    &.is-boxedImage {
      margin-bottom: 4rem;
    }
   .imageWrapper {
     display: flex;
     width: 100%;
     height: 100%;
     &.img-alignment-top {
       padding-top: 2rem;
       img, picture {
         align-self: flex-start;
       }
       .backgroundBubble {
         top: 10%;
       }
     }
     &.img-alignment-center {
       img, picture {
         align-self: center;
       }
       .backgroundBubble {
         top: 75%;
         transform: translateY(-50%);
       }
     }
     &.img-alignment-bottom {
       img, picture {
         align-self: flex-end;
       }
       .backgroundBubble {
         top: 100%;
         transform: translateY(-80%);
       }
     }
     img, picture {
       margin: 0 auto;
       &.is-boxedImage {
         position: relative;
         display: block;
         margin: 0.75rem auto 0 auto;
         box-shadow: 0 0 1rem $color-black;
         background: $color-white;
         @include media-breakpoint-up(md) {
           margin: 0;
         }
         &.right {
           margin-left: auto;
         }
         &.has-padding {
           padding: 0.5rem;
         }
       }
       &.has-shadow img {
         box-shadow: 0 0 1rem $color-black;
       }
     }

     .backgroundBubble {
       position: absolute;
       &.left {
         left: -8rem;
         top: 45%;
       }
       &.right {
         right: -10rem;
       }
     }
   }
  }
}