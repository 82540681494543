section.area-quote-slider.brick {

  .content {
    padding: 4rem 0;
    background: $color-green-light;

    .quoteItems {
      .quoteItem {
        width: 100%;
        .innerWrapper {
          width: 100%;
          max-width: 41rem;
          margin: 0 auto;
          text-align: center;
          padding: 3rem 1rem;

          .quoteIcon {
            width: 5rem;
            height: 5rem;
            background: $color-primary;
            color: $color-white;
            text-align: center;
            font-size: 2.5rem;
            border-radius: 5rem;
            margin: 0 auto 3.25rem auto;
            box-shadow: 0 0 3rem -0.25rem $color-primary;

            i {
              position: relative;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          .quote {
            @include font-h5;
            font-weight: $font-weight-regular;
            color: $color-black;
            margin-bottom: 2.5rem;
          }

          .author {
            .name {
              font-size: 1.25rem;
              font-weight: $font-weight-bold;
              color: $color-black;
              margin-bottom: 0.25rem;
            }

            .info {
              @include font-h5;
              font-weight: $font-weight-bold;
              color: $color-grey;
            }
          }
        }
      }
      .slick-dots {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -3rem;
        padding: 0;
        li {
          display: inline-block;
          button {
            width: 0.75rem;
            height: 0.75rem;
            background: $color-grey;
            border-radius: 0.75rem;
            font-size: 1px;
            text-indent: -9999px;
            margin: 0 0.25rem;
            border: none;
            padding: 0;
            cursor: pointer;
            outline: none;
          }
          &.slick-active button {
            background: $color-primary;
          }
        }
      }
    }
  }
}