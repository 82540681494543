.area-logo-slider {
  padding-top: 0;
  &__content {

    .logoSliderWrapper {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 1.5rem;

      &.slick-initialized {
        display: block;
        flex-wrap: unset;
        .logoSliderItem {
          flex: unset;
        }
      }

      .logoSliderItem {
        flex: 1 0 21%;

        position: relative;
        display: block;
        height: 7.5rem;
        padding: 0.5rem 1rem;

        .logoSliderImage {
          position: relative;
          display: block;
          top: 50%;
          transform: translateY(-50%);
          margin: 0 auto;
        }
      }

      .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        padding: 0;

        &:before {
          content: '';
        }

        &.slick-prev {
          left: 0;
        }

        &.slick-next {
          right: 0;
        }

      }
    }
  }
}