.area-image-wave {
  padding-top: 1rem;
  padding-bottom: 0;

  &__content {
    .waveWrapper {
      position: relative;
      width: 100%;
      padding-top: 80%;
      background: $color-green-light center center;
      background-size: cover;
      -webkit-mask-image: url('/static/img/wave.svg');
      -webkit-mask-size: cover;
      mask-image: url('/static/img/wave.svg');
      mask-size: cover;

      @include media-breakpoint-up(sm) {
        padding-top: 50%;
      }
      @include media-breakpoint-up(md) {
        padding-top: 45%;
      }
      @include media-breakpoint-up(lg) {
        padding-top: 39%;
      }

      .backgroundOverlay {
        @include background-overlay;
      }

      .innerWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-top: 3rem;
        width: 90%;
        max-width: 44rem;
        &.textColor-white {
          .headline,
          .content {
            color: $color-white;
          }
        }
        &.textColor-black {
          .headline,
          .content {
            color: $color-black;
          }
        }
        .headline {
          @include font-h3();
          @include media-breakpoint-up(sm) {
            @include font-h2();
            margin-bottom: 1rem;
          }
          text-align: center;
          margin-bottom: 1rem;
        }
        .content {
          @include font-copy-s();
          @include media-breakpoint-up(sm) {
            @include font-copy-l();
            margin: 0 auto;
          }
          max-width: 39rem;
          margin: 0 auto;
        }
        .buttonWrapper {
          margin-top: 1rem;
          text-align: center;
          @include media-breakpoint-up(sm) {
            margin-top: 2rem;
          }
        }
      }
    }
  }

}