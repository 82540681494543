@import "bricks/global";
@import "bricks/wysiwyg";
@import "bricks/video";
@import "bricks/cta";
@import "bricks/teaser";
@import "bricks/contact-from";
@import "bricks/logo-slider";
@import "bricks/wysiwyg-media";
@import "bricks/faq-list";
@import "bricks/feature-boxes";
@import "bricks/image-wave";
@import "bricks/quotes-slider";
@import "bricks/calendly-widget";
@import "bricks/price-info";
@import "bricks/media";