.area-contact-form {
  background: $color-green-light;
  .brick__header {
    width: 100%;
    max-width: 40rem;
    margin: 0 auto;
  }
  .custom-alert {
    .alert {
      width: 100%;
      max-width: 34rem;
      margin: 1rem auto;
    }
  }
  .formWrapper {
    position: relative;
    width: 100%;
    max-width: 34rem;
    @include font-copy-s();
    margin: 1rem auto;
    .loadingOverlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(244, 249, 243,0.8);
      .loader {
        position: absolute;
        display: block;
        width: 3.5rem;
        height: 1rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        .bounce {
          width: 1rem;
          height: 1rem;
          background-color: $color-green;
          border-radius: 100%;
          display: inline-block;
          animation: bounceLoader 1.4s infinite ease-in-out both;
        }
        .bounce1 {
          animation-delay: -0.32s;
        }
        .bounce2 {
          animation-delay: -0.16s;
        }
      }
    }
  }
  .form-group {
    position: relative;
    padding-top: 15px;
    &.has-success,
    &.has-warning,
    &.has-error {
      label {
        color: $color-black;
      }
    }

    .form-check {
      padding: 0;
      .form-check-input {
        position: relative;
        display: inline-block;
        top: 2px;
        width: 1rem;
        height: 1rem;
        background: $color-white;
        border: 1px solid $color-grey;
        border-radius: $border-radius;
        margin-right: 2px;
        padding: 0;
      }
      label {
        position: relative;
        top: 0;
        left: 0;
      }
    }

    .select-wrapper {
      border: 1px solid $color-grey;
      border-radius: $border-radius;
      background: $color-white;
      select {
        border: none;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        @include font-copy-s();
        padding: 1.25rem 1.5rem;
        height: 3.4375rem;
      }
      label {
        top: 1px;
      }
    }

    label {
      margin-bottom: 0.25rem;
      strong {
        font-weight: $font-weight-bold;
      }
    }

    input, textarea {
      border: 1px solid $color-grey;
      border-radius: $border-radius;
      box-shadow: none;
      background: $color-white;
      padding: 1.25rem 1.5rem;
      @include font-copy-s();
      &::placeholder {
        color: $color-grey;
      }
    }
    input {
      height: 3.4375rem;
    }

  }
}

@keyframes bounceLoader {
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
}