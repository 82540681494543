.brick {
    padding: $brick-padding 0;
    &.background-light-green {
        background: $color-green-light;
    }
    &.background-grey {
        background: $color-light-grey;
    }
    .brick {
        margin-top: 0;
        margin-bottom: 0;
        padding: $brick-in-brick-padding 0;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }

    &:last-of-type {

    }

    &__header {
        margin-bottom: $brick-header-bottom-space;
        &--reduced {
            margin-bottom: ($brick-header-bottom-space / 2);
        }
        &--headline,
        &--sub-headline,
        &--info-text {
            &, > * {
                width: 100%;
            }
        }
        &--info-text {
            margin-top: 1rem;
        }
    }

    &__footer {
        margin-top: $brick-footer-top-space;
        margin-bottom: $brick-footer-top-space;
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
        .brick__footer {
            margin-top: ($brick-footer-top-space / 2);
        }
        .cta-button-item {
            &__sub-text {
                margin: 5px 0 0 0;
            }
        }
        &.justify-content-start {
            .cta-button-item {
                margin: 0 10px 0 0;
                text-align: left;
            }
        }
        &.justify-content-center {
            .cta-button-item {
                margin: 0 10px;
                text-align: center;
            }
        }
        &.justify-content-end {
            .cta-button-item {
                margin: 0 0 0 10px;
                text-align: right;
            }
        }

    }

}