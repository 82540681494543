footer {
  position: relative;
  padding: 4.25rem 0 0 0;
  background: $color-light-grey;
  .separatorLine {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0.25rem;
    background: $color-green-gradient;
  }

  section.leadFormSection {
    .container {
      padding-bottom: 2rem;
      &.has-border {
        border-bottom: 2px solid $color-light-grey-active;
      }
      .title {
        color: $color-primary;
      }
      .info {
        margin-top: 0.25rem;
      }
      form.emailLeadForm {
        position: relative;
        margin-top: 0.75rem;
        @include media-breakpoint-up(md) {
        }
        .form-group {
          margin-bottom: 0;
        }
        .privacyNote {
          @include font-copy-s;
          position: relative;
          display: block;
          opacity: 0;
          padding: 0 0.25rem;
          margin-top: 0.25rem;
          transition: opacity .5s ease;
          &.is-visible {
            opacity: 1;
          }
        }
      }
    }
  }

  section.linkRowSection {
    padding: 2rem 0;
    nav {
      ul {
        margin: 0;
        text-align: center;
        li {
          display: inline-block;
          margin: 0.25rem 1rem;
          a {
            color: $color-black;
            font-size: 1.1rem;
            font-weight: $font-weight-medium;
            text-decoration: none;
            &:hover {
              color: $color-primary;
            }
          }
        }
      }
    }
  }

  section.linkListSection {
    padding: 4.25rem 0;
    .columnTitle {
      font-size: 1rem;
      font-weight: $font-weight-bold;
      letter-spacing: 1px;
      line-height: 1.2rem;
      margin-bottom: 0.75rem;
    }
    nav {
      ul {
        margin-bottom: 2rem;
        li {
          margin-bottom: 0.75rem;
          a {
            color: $color-black;
            font-size: 1rem;
            line-height: 1.4375rem;
            text-decoration: none;
            &:hover {
              color: $color-primary;
            }
          }
        }
      }
    }
  }

  section.bottomLinksSection {
    background: $color-white;
    padding: 1.5rem 0;
    .bottomLinkListWrapper {
      text-align: center;
      margin-bottom: 1rem;
      @include media-breakpoint-up(xl) {
        text-align: left;
        margin-bottom: 0;
      }
      .languageSwitch {
        display: inline-block;
        margin: 0 1rem;
        line-height: 2.75rem;
        @include media-breakpoint-up(xl) {
          margin-left: 0;
        }
        a.dropdown-toggle {
          &:after {
            color: $color-grey;
          }
          .flag {
            position: relative;
            top: -1px;
            width: 1.5rem;
            height: 1.5rem;
          }
          span {
            margin-left: 0.25rem;
            color: $color-grey;
            font-size: 1rem;
            line-height: 2.75rem;
            font-weight: $font-weight-bold;
            letter-spacing: 1px;
            text-decoration: none;
          }
        }
        .dropdown-menu {
          min-width: auto;
          margin: 0.5rem 0;
          .dropdown-item {
            padding: 0 0.5rem;
            .flag {
              width: 1.5rem;
              height: 1.5rem;
            }
            span {
              margin-left: 0.25rem;
              font-weight: $font-weight-semi-bold;
            }
          }
        }
      }

      nav.legalLinks {
        display: inline-block;
        ul {
          margin: 0;
          li {
            margin: 0 1rem;
            a {
              color: $color-grey;
              font-size: 1rem;
              line-height: 2.75rem;
              font-weight: $font-weight-bold;
              letter-spacing: 1px;
              text-decoration: none;
            }
          }
        }
      }
    }

    .socialMediaIcon {
      line-height: 2.75rem;
      font-size: 1.5rem;
      margin: 0 0.5rem;
      &.facebook {
        color: #1877f2;
      }
      &.twitter {
        color: #1da1f2;
      }
      &.instagram {
        color: #a636b9;
      }
      &.linkedIn {
        color: #0077b5;
      }
      &.youtube {
        color: #ff0203;
      }
      @include media-breakpoint-up(sm) {
        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .infoBadgeRow {
      margin-top: 1.5rem;
      .infoBadge {
        position: relative;
        display: inline-block;
        height: 2.75rem;
        padding: 0.5rem 0.5rem 0.5rem 2.5rem;
        background: $color-white;
        box-shadow: $default-shadow;
        border-radius: $border-radius;
        text-align: center;
        margin: 1rem 0.5rem 0 0.5rem;
        @include media-breakpoint-up(sm) {
          margin: 0 0.75rem;
        }

        .icon {
          position: absolute;
          left: 0.5rem;
          top: 50%;
          width: 1.5rem;
          height: 1.5rem;
          transform: translateY(-50%);
          &.round {
            border-radius: 1.5rem;
            overflow: hidden;
          }
          i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: $color-green;
            font-size: 1.25rem;
          }
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
        .firstInfoLine {
          font-size: $font-size-copy-m;
          font-weight: $font-weight-semi-bold;
          letter-spacing: 0.5px;
          line-height: 1.1;
        }
        .secondInfoLine {
          font-size: $font-size-copy-s;
          letter-spacing: 0.5px;
          line-height: 1.1;
        }
      }
    }
  }
}