@mixin headerItemHeight {
  height: ($header-height-mobile - ($header-space-mobile * 2));
  @include media-breakpoint-up(sm) {
    height: ($header-height-main-content-tablet - ($header-space-tablet * 2));
  }
  @include media-breakpoint-up(lg) {
    height: $header-height-main-content-desktop;
  }
}

@mixin menuNavButton($textColor, $backgroundColor, $backgroundColorActive) {
  margin: 0 0.25rem;
  a {
    background: $backgroundColor;
    color: $textColor;
    padding: 0.75rem 1rem;
    border-radius: $border-radius;
    @include media-breakpoint-up(md) {
      padding: 0.5rem 0.75rem;
    }

    &:hover {
      background: $backgroundColorActive;
    }
  }
}

header {
  position: fixed;
  top: 0;
  z-index: 200;
  max-width: 120rem;
  width: 100%;
  height: $header-height-mobile;
  background-color: #FFFFFF;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 0.25rem solid transparent;

  @include media-breakpoint-up(sm) {
    height: $header-height-tablet;
  }
  @include media-breakpoint-up(lg) {
    height: $header-height-desktop;
  }

  .contactInfoHeader {
    display: block;
    position: relative;
    max-width: 120rem;
    width: 100%;
    height: 2rem;
    background: $color-green;
    color: $color-white;
    line-height: 2rem;
    .innerWrapper {
      text-align: right;
      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }
    }
    span {
      padding: 0 0 0 0.75rem;
    }
    a {
      color: $color-white;
      font-weight: $font-weight-semi-bold;
    }
  }

  .mainContent {
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }
    }
  }

  .bottomBorder {
    position: absolute;
    bottom: -0.25rem;
    left: 0;
    right: 0;
    height: 0.25rem;
    background: $color-green-gradient;
  }

  body.editmode & {
    position: relative;
  }

  .logo-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    .logo {
      position: relative;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    nav.main-navigation {
      text-align: right;
      @include headerItemHeight();

      ul.list {
        position: relative;
        top: 50%;
        height: 1.2rem;
        margin: 0;
        transform: translate(0, -50%);

        li.item {
          position: relative;
          font-size: 1rem;
          line-height: 1.2rem;
          font-weight: $font-weight-bold;
          margin: 0 0.5rem;
          letter-spacing: 0.5px;

          @include media-breakpoint-up(xl) {
            margin: 0 0.6rem;
          }

          &:hover,
          &:active {
            > a {
              color: $color-primary;
            }
            @include media-breakpoint-up(lg) {
              .sub-navigation {
                display: block;
              }
            }
          }

          a {
            color: $color-black;
            transition: all .1s ease-in-out;
            padding-bottom: 1.5rem;

            &.active,
            &:hover {
              color: $color-primary;
              text-decoration: none;
            }
          }

          &.nav-button-primary {
            @include menuNavButton($color-white, $color-green, $color-green-active);
          }

          &.nav-button-secondary {
            @include menuNavButton($color-white, $color-orange, $color-orange-active);
            padding-left: 0.25rem;
            padding-right: 0.25rem;
          }

          &.nav-button-default {
            @include menuNavButton($color-black, $color-light-grey, $color-light-grey-active);
            margin: 0 0.25rem;
          }

          &:last-of-type {
            margin-right: 0;
          }

          .sub-navigation {
            display: none;
            position: absolute;
            background: $color-white;
            text-align: left;
            top: 2.75rem;
            left: -1rem;
            width: 16rem;
            padding: 0.5rem 1rem;
            box-shadow: $box-shadow;
            font-weight: $font-weight-regular;

            li {
              margin-bottom: 0.75rem;
              &:last-of-type {
                margin-bottom: 0;
              }
              a {
                &.active {
                  font-weight: $font-weight-semi-bold;
                }
              }
            }
          }
        }
      }
    }
  }
}

