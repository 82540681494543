@mixin make-font($size: null, $weight: null, $family: $font-family-base, $line-height: null, $color: null) {
    @if $color {
        color: $color
    }
    @if $size {
        @include flow-font-size($size)
    }
    @if $weight {
        font-weight: $weight
    }
    @if $family {
        font-family: $family
    }
    @if $line-height {
        @include flow-line-height($line-height)
    }
    margin: 0;
}

@mixin font-h1 {
    @include make-font($font-size-h1-flow, $font-weight-bold, $font-family-galano-grotesque, $line-height-h1-flow, $color-black);
}

@mixin font-h2 {
    @include make-font($font-size-h2-flow, $font-weight-bold, $font-family-galano-grotesque, $line-height-h2-flow, $color-black);
}

@mixin font-h3 {
    @include make-font($font-size-h3-flow, $font-weight-bold, $font-family-galano-grotesque, $line-height-h3-flow, $color-black);
}

@mixin font-h4 {
    @include make-font($font-size-h4-flow, $font-weight-bold, $font-family-galano-grotesque, $line-height-h4-flow, $color-black);
}

@mixin font-h5 {
    @include make-font($font-size-h5-flow, $font-weight-bold, $font-family-galano-grotesque, $line-height-h5-flow, $color-black);
}

@mixin font-copy-xl {
    @include make-font($font-size-copy-xl-flow, $font-weight-regular, $font-family-galano-grotesque, $line-height-copy-xl-flow, $color-black);
}

@mixin font-copy-l {
    @include make-font($font-size-copy-l-flow, $font-weight-regular, $font-family-galano-grotesque, $line-height-copy-l-flow, $color-black);
}

@mixin font-copy-m {
    @include make-font($font-size-copy-m-flow, $font-weight-regular, $font-family-galano-grotesque, $line-height-copy-m-flow, $color-black);
}

@mixin font-copy-s {
    @include make-font($font-size-copy-s-flow, $font-weight-regular, $font-family-galano-grotesque, $line-height-copy-s-flow, $color-black);
}


/**
 * @license
 * MyFonts Webfont Build ID 3760259, 2019-05-15T05:32:10-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: GalanoGrotesque-Bold by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/bold/
 *
 * Webfont: GalanoGrotesque-ExtraLight by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/extra-light/
 *
 * Webfont: GalanoGrotesque-Heavy by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/heavy/
 *
 * Webfont: GalanoGrotesque-Medium by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/medium/
 *
 * Webfont: GalanoGrotesque-Regular by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/regular/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3760259
 * Licensed pageviews: 10,000
 * Webfonts copyright: Copyright &#x00A9; 2014 by Ren&#x00E9; Bieder. All rights reserved.
 *
 * © 2019 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/396083");
@import url("//hello.myfonts.net/count/3962ab");

/* GalanoGrotesque-ExtraLight */
@font-face {
    font-family: 'GalanoGrotesque';
    font-weight: 200;
    font-display: swap;
    src:url('/static/fonts/galano-grotesque/3C85D1_1_0.woff2') format('woff2'),
    url('/static/fonts/galano-grotesque/3C85D1_1_0.woff') format('woff');
}

/* GalanoGrotesque-Regular */
@font-face {
    font-family: 'GalanoGrotesque';
    font-weight: 400;
    font-display: swap;
    src:url('/static/fonts/galano-grotesque/3C85D1_4_0.woff2') format('woff2'),
    url('/static/fonts/galano-grotesque/3C85D1_4_0.woff') format('woff');
}

/* GalanoGrotesque-Medium */
@font-face {
    font-family: 'GalanoGrotesque';
    font-weight: 500;
    font-display: swap;
    src:url('/static/fonts/galano-grotesque/3C85D1_3_0.woff2') format('woff2'),
    url('/static/fonts/galano-grotesque/3C85D1_3_0.woff') format('woff');
}

/* GalanoGrotesque-SemiBold */
@font-face {
    font-family: 'GalanoGrotesque';
    font-weight: 600;
    font-display: swap;
    src:url('/static/fonts/galano-grotesque/3C85E2_0_0.woff2') format('woff2'),
    url('/static/fonts/galano-grotesque/3C85E2_0_0.woff') format('woff');
}

/* GalanoGrotesque-Bold */
@font-face {
    font-family: 'GalanoGrotesque';
    font-weight: 700;
    font-display: swap;
    src:url('/static/fonts/galano-grotesque/3C85D1_0_0.woff2') format('woff2'),
    url('/static/fonts/galano-grotesque/3C85D1_0_0.woff') format('woff');
}

/* GalanoGrotesque-Heavy */
@font-face {
    font-family: 'GalanoGrotesque';
    font-weight: 800;
    font-display: swap;
    src:url('/static/fonts/galano-grotesque/3C85D1_2_0.woff2') format('woff2'),
    url('/static/fonts/galano-grotesque/3C85D1_2_0.woff') format('woff');
}
